import Mousetrap from 'mousetrap'

function activateSearchBox() {
  const search = document.querySelector('[type=search]')
  if (search) {
    search.focus()
  }
}

Mousetrap.bind('/', () => {
  activateSearchBox()
  return false
})
