import { Controller } from '@hotwired/stimulus'
import Choices from 'choices.js/public/assets/scripts/choices'

export default class extends Controller {
  static targets = [
    'choice',
  ]

  connect() {
    const className = 'choices__inner'

    // First check if this has already been initialized
    if (this.choiceTarget.parentNode.className.split(' ').indexOf(className) >= 0) {
      return
    }

    this.choices = new Choices(this.choiceTarget, {
      allowHTML: false,
      itemSelectText: 'Click to select',
      shouldSortItems: true,
      searchFields: ['label'],
    })
  }
}
