import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'submissionType',
    'form',
  ]

  connect() {
    if (this.submissionTypeTarget.id === '') {
      this.submissionTypeTarget.id = Math.random().toString(36)
    }
  }

  softSubmit() {
    this.submissionTypeTarget.value = 'soft'
    this.formTarget.requestSubmit()
  }
}
