import { Controller } from '@hotwired/stimulus'
import 'bootstrap-table/dist/bootstrap-table'
import 'bootstrap-table/dist/extensions/cookie/bootstrap-table-cookie'
import jquery from 'jquery'

window.htmlColumnSorter = (a, b) => {
  const aText = jquery(a).text()
  const bText = jquery(b).text()

  if (aText < bText) {
    return -1
  }

  if (aText > bText) {
    return 1
  }

  return 0
}

export default class extends Controller {
  static targets = ['table']

  connect() {
    const $table = jquery(this.tableTarget)

    // Prevent ourselves from initializing a table that has already been loaded
    if ($table.attr('initialized') === 'true') {
      return
    }

    $table.attr('initialized', true)

    $table.bootstrapTable()
  }
}
